import styled from '@emotion/styled';
import { ModuleTile, ModuleTileInner, Grid, Page } from '@zola/zola-ui/src/components/Grid';
import SupportAccordion from '@zola/zola-ui/src/components/PreAuthV3/SupportAccordion';
import {
  ContentWrapper,
  StyledHTitle3,
} from '@zola/zola-ui/src/components/PreAuthV3/SupportAccordion.styles';
import { Question, Answer } from '@zola/zola-ui/src/components/PreAuthV3/FAQItem.styles';
import {
  AccordionSectionHeading,
  StyledCaret,
} from '@zola/zola-ui/src/components/AccordionV2/AccordionV2.styles';
import Logos from '@zola/zola-ui/src/components/PreAuthV3/Logos';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import COLORS from '@zola/zola-ui/src/styles/emotion/colorsBaby';
import FONTS from '@zola/zola-ui/src/styles/emotion/fonts';
import {
  ClearIconContainer,
  CoupleSearchAddOn,
} from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3/InputFieldV3.styles';
import { PrimaryButton } from 'components/zola-ui/ButtonV3';

export const StyledPage = styled(Page)`
  margin-left: auto;
  margin-right: auto;
`;

export const TileGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 100vw;
  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    max-width: 1920px;
  }
  ${MEDIA_QUERY.DESKTOP} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const GridTile = styled.div<{ background?: string }>`
  background-color: ${props => (props.background ? props.background : COLORS.WHITE_100)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  aspect-ratio: 1 / 1;
  ${MEDIA_QUERY.MOBILE} {
    padding: ${SPACING.S16};
    &:nth-child(3n + 3) {
      grid-column: 2 / 3;
    }
    &:nth-child(4n + 4) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
    h2 {
      ${FONTS.textSizeUltraCompact('18px')}
    }
  }
  ${MEDIA_QUERY.TABLET} {
    padding: ${SPACING.S32};
    &:nth-child(3n + 3) {
      grid-column: 2 / 3;
    }
    &:nth-child(4n + 4) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
  }
  ${MEDIA_QUERY.DESKTOP} {
    padding: 40px;
  }
  ${MEDIA_QUERY.DESKTOP_LG} {
    padding: 64px;
  }
  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    h2 {
      font-size: ${FONTS.SIZE.SEMI_HUGE};
      line-height: ${FONTS.SIZE.SEMI_HUGE};
    }
  }
`;

export const BabyBackgroundTile = styled(ModuleTile)`
  aspect-ratio: 1280 / 300;
  max-height: 376px;
  width: 100%;
  ${ModuleTileInner} {
    background-image: url('${process.env
      .ASSET_PATH}/static-baby/images/backgrounds/baby_wallpaper.svg');
    background-size: 86%;
    background-position-y: -113%;
    background-repeat: repeat;
    height: 100%;
  }
`;

export const BabyBackgroundGrid = styled(Grid)`
  display: none;
  ${MEDIA_QUERY.DESKTOP} {
    display: initial;
  }
`;

export const FaqModuleTile = styled(ModuleTile)`
  max-width: 100vw;
  ${ModuleTileInner} {
    background-color: ${COLORS.BLUE_PERI_100};
    border-radius: 16px;
    ${MEDIA_QUERY.MOBILE} {
      padding-right: 24px;
      padding-left: 24px;
    }
  }
  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    max-width: 1920px;
  }
  ${MEDIA_QUERY.MOBILE} {
    padding: ${SPACING.S8};
  }
`;

export const FaqAccordion = styled(SupportAccordion)`
  background-color: ${COLORS.BLUE_PERI_100};
  ${MEDIA_QUERY.MOBILE} {
    padding: 16px 0;
    margin: 0;
  }
  ${ContentWrapper} {
    ${StyledHTitle3},
    ${AccordionSectionHeading}, ${StyledCaret}, ${Question}, ${Answer} {
      color: ${COLORS.BLACK_100}!important;
    }
    ${StyledHTitle3} {
      ${MEDIA_QUERY.MOBILE} {
        text-align: left;
        max-width: 100%;
      }
    }
  }
  ${StyledHTitle3} span {
    font-family: ${FONTS.FONT_FAMILY_CIRCULAR};
    font-weight: ${FONTS.FONT_WEIGHT_SEMI_BOLD};
  }
`;

export const BrandLogos = styled(Logos)``;

export const SearchTile = styled(ModuleTile)`
  max-width: 100vw;
  ${MEDIA_QUERY.TABLET} {
    text-align: center;
  }
  ${MEDIA_QUERY.DESKTOP} {
    text-align: center;
    ${ModuleTileInner} {
      padding: 40px;
    }
  }
  ${MEDIA_QUERY.DESKTOP_XL} {
    h2 {
      font-size: ${FONTS.SIZE.SEMI_HUGE};
      line-height: ${FONTS.SIZE.SEMI_HUGE};
    }
  }
  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    max-width: 1920px;
  }
`;

export const InputWrapper = styled.div`
  ${MEDIA_QUERY.DESKTOP} {
    margin-top: ${SPACING.S24};
  }
  ${MEDIA_QUERY.DESKTOP_LG} {
    margin-top: ${SPACING.S32};
  }
  ${MEDIA_QUERY.MOBILE_XS} {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
  }
  input {
    border-radius: 100px;
    background-color: ${COLORS.BLACK_005};
    border: none;
    height: 56px;
    padding: ${SPACING.S16} ${SPACING.S24};
    padding-left: 50px !important;

    ${MEDIA_QUERY.EXCLUDE_MOBILE_XS} {
      width: 389px;
    }
  }

  ${CoupleSearchAddOn} {
    top: 16px;
    left: 20px;
    width: 24px !important;
    height: 24px !important;
  }

  ${ClearIconContainer} {
    top: 22px;
    right: 18px;
  }

  button {
    margin-left: ${SPACING.XS};

    ${MEDIA_QUERY.MOBILE_XS} {
      align-self: flex-start;
      margin-left: 0;
    }
  }
`;

export const SearchForm = styled.form`
  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: none;
  }
`;

export const SearchButton = styled(PrimaryButton)`
  margin-top: ${SPACING.S16};
  ${MEDIA_QUERY.TABLET} {
    margin-top: ${SPACING.S24};
  }
  ${MEDIA_QUERY.DESKTOP} {
    display: none;
  }
`;
