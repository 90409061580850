import { GetServerSideProps } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import { useState } from 'react';

// Components
import { Grid } from '@zola/zola-ui/src/components/Grid';
import H from '@zola/zola-ui/src/typography/Headings';
import T from '@zola/zola-ui/src/typography/Text';
import InputFieldV3 from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3';
import { InputFieldAddOnType } from '@zola/zola-ui/src/components/Form/InputFieldV2/InputFieldAddOn';
import BabyFooter from '@zola/zola-ui/src/components/footers/baby/BabyFooter';
import Nav from 'components/Nav';
import BabyBackgroundModuleTile from 'components/ui/BabyBackgroundModuleTile';
import Hero from 'components/Homepage/Hero';
import { PrimaryButton } from 'components/zola-ui/ButtonV3';

// Styles
import COLORS from '@zola/zola-ui/src/styles/emotion/colorsBaby';

// Context
import { DeviceProvider } from 'contexts/DeviceContext';
import { ToastsProvider } from 'contexts/Toasts/ToastsContext';

// Types
import type IncomingMessageWithServerTiming from 'types/IncomingMessageWithServerTiming';

// API
import { fetchNavHTML } from 'libs/server/nav';
import { fetchUser } from 'libs/server/users';
import { UserContext } from '@zola-helpers/client/dist/es/@types';

import LogoCoterie from 'public/static-baby/images/homepage/Coterie.svg';
import LogoEZPZ from 'public/static-baby/images/homepage/EZPZ.svg';
import LogoLovevery from 'public/static-baby/images/homepage/Lovevery.svg';
import LogoMaxiCosi from 'public/static-baby/images/homepage/MaxiCosi.svg';
import LogoNanit from 'public/static-baby/images/homepage/Nanit.svg';
import LogoNuna from 'public/static-baby/images/homepage/Nuna.svg';
import LogoStokke from 'public/static-baby/images/homepage/Stokke.svg';
import LogoTinyhood from 'public/static-baby/images/homepage/Tinyhood.svg';
import LogoUPPAbaby from 'public/static-baby/images/homepage/UPPAbaby.svg';

import {
  GridTile,
  StyledPage,
  FaqAccordion,
  BrandLogos,
  InputWrapper,
  SearchTile,
  SearchButton,
  SearchForm,
  FaqModuleTile,
  TileGrid,
} from 'components/Homepage/styles';

const HpGrid1 = '/static-baby/images/homepage/grid_1.jpg';
const HpGrid2 = '/static-baby/images/homepage/grid_2.jpg';
const HpGrid3 = '/static-baby/images/homepage/grid_3.jpg';

const metaTitle = 'Create your Baby Registry';
const metaDescription =
  "Join Zola's Baby Registry. Get ready for your little one's arrival with our top-rated essentials, from strollers to nursery decor. Get started now!";

export type HomepageProps = {
  navHTML: string | null;
  userAgent: string | undefined;
  userCtx: UserContext | null;
};

const Homepage: React.FC<HomepageProps> = ({ navHTML, userAgent, userCtx }): JSX.Element => {
  const [query, setQuery] = useState<string>('');

  const handleSearchClick = (e: React.FormEvent): void => {
    e.preventDefault();
    if (query) {
      window.location.href = `/search/baby-registry?query=${query}`;
    }
  };

  return (
    <DeviceProvider userAgent={userAgent}>
      <Head>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta
          property="og:image"
          content="https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/logos/ZOLABABY_ICON-L_BLUE.png"
        />
        <link rel="canonical" href="https://baby.zola.com" />
        <meta name="twitter:site" content="@zola" />
        <meta name="twitter:card" content="summary" />
      </Head>
      <Nav navHTML={navHTML} user={userCtx} overlay />
      <ToastsProvider>
        <StyledPage id="home">
          <Grid>
            <Hero />
            <BrandLogos
              logos={[
                {
                  desktopSrc: LogoUPPAbaby,
                  imgType: 'svg',
                  mobileSrc: LogoUPPAbaby,
                  tabletSrc: LogoUPPAbaby,
                },
                {
                  desktopSrc: LogoLovevery,
                  imgType: 'svg',
                  mobileSrc: LogoLovevery,
                  tabletSrc: LogoLovevery,
                },
                {
                  desktopSrc: LogoNanit,
                  imgType: 'svg',
                  mobileSrc: LogoNanit,
                  tabletSrc: LogoNanit,
                },
                {
                  desktopSrc: LogoStokke,
                  imgType: 'svg',
                  mobileSrc: LogoStokke,
                  tabletSrc: LogoStokke,
                },
                {
                  desktopSrc: LogoCoterie,
                  imgType: 'svg',
                  mobileSrc: LogoCoterie,
                  tabletSrc: LogoCoterie,
                },
                {
                  desktopSrc: LogoMaxiCosi,
                  imgType: 'svg',
                  mobileSrc: LogoMaxiCosi,
                  tabletSrc: LogoMaxiCosi,
                },
                {
                  desktopSrc: LogoTinyhood,
                  imgType: 'svg',
                  mobileSrc: LogoTinyhood,
                  tabletSrc: LogoTinyhood,
                },
                {
                  desktopSrc: LogoEZPZ,
                  imgType: 'svg',
                  mobileSrc: LogoEZPZ,
                  tabletSrc: LogoEZPZ,
                },
                {
                  desktopSrc: LogoNuna,
                  imgType: 'svg',
                  mobileSrc: LogoNuna,
                  tabletSrc: LogoNuna,
                },
              ]}
            />
            <TileGrid>
              <GridTile background={COLORS.BLUE_PERI_100}>
                <H.Title2 presentation="h3">
                  Exchange gifts <T.Strong>before</T.Strong>
                  <br />
                  they ship
                </H.Title2>
              </GridTile>
              <GridTile>
                <Image
                  src={HpGrid1}
                  alt=""
                  sizes="(max-width: 1280px) 50vw, 33vw"
                  layout="fill"
                  objectFit="cover"
                  priority={false}
                />
              </GridTile>
              <GridTile background={COLORS.TEAL_SAGE_100}>
                <H.Title2 presentation="h3">
                  One-on-one
                  <br />
                  <T.Strong>support</T.Strong>
                </H.Title2>
              </GridTile>
              <GridTile>
                <Image
                  src={HpGrid2}
                  alt=""
                  sizes="(max-width: 1280px) 50vw, 33vw"
                  layout="fill"
                  objectFit="cover"
                  priority={false}
                />
              </GridTile>
              <GridTile background={COLORS.TAN_CLOUD_100}>
                <H.Title2 presentation="h3">
                  Use your <T.Strong>Zola credit</T.Strong>
                  <br />
                  on Zola Baby
                </H.Title2>
              </GridTile>
              <GridTile>
                <Image
                  src={HpGrid3}
                  alt=""
                  sizes="(max-width: 1280px) 50vw, 33vw"
                  layout="fill"
                  objectFit="cover"
                  priority={false}
                />
              </GridTile>
            </TileGrid>
            <SearchTile>
              <H.Title2 presentation="h3">Looking for someone&apos;s registry?</H.Title2>
              <SearchForm onSubmit={handleSearchClick}>
                <InputWrapper>
                  <InputFieldV3
                    id="baby-search-input"
                    inline
                    placeholder="Search by parent name"
                    type="text"
                    addOn={InputFieldAddOnType.COUPLE_SEARCH}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                      setQuery(e.target.value);
                    }}
                  />
                  <PrimaryButton type="submit" size="large" onClick={handleSearchClick}>
                    Search
                  </PrimaryButton>
                </InputWrapper>
              </SearchForm>
              <SearchButton component="a" href="/search/baby-registry" size="large">
                Find a registry
              </SearchButton>
            </SearchTile>
            <BabyBackgroundModuleTile background={COLORS.PD_ULTRAMARINE_100} />
            <FaqModuleTile>
              <FaqAccordion
                faqs={[
                  {
                    question: 'What is Zola Baby?',
                    answer:
                      'Zola baby is the baby registry that has it all. We are here to help you on the journey to and through parenthood. You’ll find a curated selection of the top brands, all with helpful features for busy parents like choosing when your gifts ship, virtual exchanges, and easy returns. Plus, there’s one-on-one support from our team of experts, for you and anyone shopping your registry. As well as all our affordable baby registry options you can add gifts from any store, plus gift cards so you’re prepared for anything. And for unique baby registry ideas, create a zero-fee cash fund for anything — from diapers to college!',
                  },
                  {
                    question: 'What’s different about Zola Baby?',
                    answer:
                      'Growing your family is an exciting time. And to make getting ready for your new addition as easy as possible, we’ve created the best baby registry for you — Zola Baby!  Everything is easy. Just like with Zola, you’ll find the best and most trusted brands all in one place. Plus, our universal registry means you can add gifts from anywhere so you can add eco-friendly baby registry items or luxury baby registry items, all ready for your loved ones to shop in one easy place.',
                  },
                  {
                    question: 'What are the benefits of a Zola Baby registry?',
                    answer:
                      'Zola Baby has everything parents like you want. The items you need, the brands you trust, and all the gifts your friends and family will love to shop. You’ll find everything from classic baby gifts and everyday essentials to the latest gadgets that new parents are obsessed with and cash funds that let you plan for your little one’s big future. During this busy time, there’s no need to go hunting for the best items for your baby — we have them all right here! But it’s not just about the great gifts, it’s about how we’ve simplified the whole process. Not sure what to register for? Try our baby registry checklist. Want to exchange feeding accessories without waiting in line at a department store? It’s a piece of cake with our easy online returns. Need to know who to thank? We track every gift for you, for free. Plus, get one-on-one support you won’t find anywhere else from a real, live Zola advisor who gets it. They can provide baby registry ideas, help pick out the perfect stroller based on your needs, share baby shower tips, review your registry, or just listen if you need to talk. We make registering a personal and joyful experience for you and your friends and family — the way it should be.',
                  },
                  {
                    question: 'Are there any bonus perks for using Zola Baby?',
                    answer:
                      'You’ll get a 15% discount as well! It’s our way of saying congratulations on the new addition. We’ll give you 15% off for 60 days before the baby’s expected arrival and for 30 days afterwards, up to a total saving of $300.',
                  },
                  {
                    question: 'What is a universal baby registry?',
                    answer:
                      'A universal baby gift registry brings together all your registry gifts on one page. At Zola, you can register for gifts and zero-fee cash funds from our store, add gifts from other stores, and even add an entire registry from another store. We provide the benefit of being able to add baby gifts from anywhere, with the convenience of your friends and family shopping from one place. We make it easy for you to track what’s available, what’s been purchased, and who you need to thank.',
                  },
                  {
                    question: 'What is the easiest way to get started?',
                    answer:
                      'If you’re wondering how to create a baby registry, the Zola Baby app makes it easy! Download from the App Store, and explore our checklist; filled with baby registry tips for first-time parents. We have curated a selection of the best registry items you’ll need; from the essentials (like strollers, car seats, and cribs) to the latest products that new parents are obsessed with (check out our next generation baby monitors). Plus, our team is always here to offer one-on-one support to make sure you’ve got everything you need.',
                  },
                  {
                    question: 'What if I am registering at other stores besides Zola? ',
                    answer:
                      'That’s totally fine! We’ve filled our store with some of the best and most trusted brands, but if you’ve found something special from another place, you can add it to your registry from any online store — perfect for any unique baby registry ideas. No matter what you add or where you add from, your loved ones can shop all your gifts in one place (and you can keep track of what’s been bought).',
                  },
                  {
                    question: 'Is Zola Baby really free to use?',
                    answer:
                      'Yes! We even offer a zero-fee option on our cash funds. But if you do choose to set up a cash fund and want the money deposited directly in your bank account, there is a standard 2.5% transaction fee (which we don’t make any money from). ',
                  },
                  {
                    question: 'How many gifts should I add to my Zola Baby registry?',
                    answer:
                      'Every parent is different (especially if they need a baby registry for twins!) but typically a Zola baby registry will have between 100 and 120 items, which includes everything you’ll need when the baby comes. To help you get started, our baby registry checklist will help you make sure you’ve got everything you need.Some quick must-haves: cribs, strollers, baby monitors, travel bags, and high chairs. Plus, everything you need for bedtime, bathrime, and playtime. On Zola, adding or removing items is super simple and can be done at any time, even once your registry is published. That means you can use it as a baby shower registry and then top up with more gifts when the little one arrives.',
                  },
                  {
                    question: 'How does Zola Baby work for gift givers?',
                    answer:
                      'Once you share your Zola Baby registry with your loved ones they can browse your site and shop, just like with any other online store. No app required for friends and family shopping! And if they’ve got any questions, our team is here for one-on-one support. We’ll let you know when an item has been bought. Then you decide when to ship it. ',
                  },
                  {
                    question: 'Can I shop on Zola Baby without a registry? ',
                    answer:
                      'Yes, you can! If you are just looking to purchase items, you will need to download the Zola Baby app and create an account. From there, you can go ahead and shop for all the best baby items! If you want to buy a gift off someone else’s registry, they’ll share their link for you to browse and shop online.',
                  },
                  {
                    question: 'What can I add to my Zola Baby registry?',
                    answer:
                      'The sky is truly the limit! You’ll find everything you need for you and baby, from feeding, sleeping, playing, clothing, decor and more. Not sure where to begin? Check out our baby registry checklist, filled with those must-have items for baby registry. As well as popular baby registry gifts on Zola Baby, you can add gifts from other online stores. Then there’s cash gifts. Create a zero-fee cash fund for anything you want, from diapers to college.',
                  },
                  {
                    question: 'Do our Zola credits move over to Zola Baby registry? ',
                    answer:
                      'Yes! If you planned your wedding with Zola and have leftover credits you can use them on Zola Baby for all those top baby registry essentials. And the best part is, Zola credits never expire',
                  },
                ]}
              />
            </FaqModuleTile>
            <BabyFooter />
          </Grid>
        </StyledPage>
      </ToastsProvider>
    </DeviceProvider>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  req,
}: {
  req: IncomingMessageWithServerTiming;
}) => {
  const userCtx = await fetchUser(req);

  if (userCtx?.has_baby_registry) {
    return {
      redirect: {
        destination: '/coming-soon',
        permanent: false,
      },
    };
  }

  const userAgent = req.headers['user-agent'];

  const navHTML = await fetchNavHTML(req);

  return {
    props: {
      userAgent,
      navHTML,
      userCtx,
    },
  };
};

export default Homepage;
