// Styles
import COLORS from '@zola/zola-ui/src/styles/emotion/colorsBaby';
import { BabyTile } from './BabyBackgroundModuleTile.styles';

export type BabyBackgroundModuleTileProps = {
  background?: string;
};

const BabyBackgroundModuleTile: React.FC<BabyBackgroundModuleTileProps> = ({
  background = COLORS.PD_ULTRAMARINE_100,
}): JSX.Element => {
  return <BabyTile background={background} />;
};

export default BabyBackgroundModuleTile;
