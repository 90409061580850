import { toastsReducer, toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import { ToastAction } from '@zola-helpers/client/dist/es/redux/toasts/toastActionTypes';
import { ToastsV2 } from '@zola/zola-ui/src/components/ToastsV2/index';
import { ToastReducerState } from '@zola-helpers/client/dist/es/redux/toasts/toastsReducer';
import React, { createContext, FC, useContext, Context, useReducer } from 'react';
import { ToastV2Options } from '@zola/zola-ui/src/components/ToastsV2/types/ToastV2Options';

type ToastsContextType = {
  dispatchToastAction: (value: ToastAction) => void;
  state: ToastReducerState;
};

const ToastsContext: Context<ToastsContextType> = createContext<ToastsContextType>({
  dispatchToastAction: () => undefined,
  state: [],
});

const { Provider } = ToastsContext;

const ToastsProvider: FC = ({ children }) => {
  const [state, dispatchToastAction] = useReducer(toastsReducer, []);
  return (
    <Provider value={{ state, dispatchToastAction }}>
      <ToastsV2
        toasts={state as ToastV2Options[]}
        onRemoveToast={(id: number) => dispatchToastAction(toastsActions.hide(id))}
      />
      {children}
    </Provider>
  );
};

const useToastsContext = (): ToastsContextType => useContext(ToastsContext);

export { ToastsProvider, useToastsContext };
