import styled from '@emotion/styled';
import { ModuleTile, ModuleTileInner } from '@zola/zola-ui/src/components/Grid';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

export const BabyTile = styled(ModuleTile)`
  aspect-ratio: 1280 / 300;
  max-height: 376px;
  width: 100%;
  max-width: 100vw;
  display: none;
  ${MEDIA_QUERY.DESKTOP_MASSIVE} {
    max-width: 1920px;
  }
  ${MEDIA_QUERY.DESKTOP} {
    display: initial;
  }
  ${ModuleTileInner} {
    background-image: url('${process.env
      .ASSET_PATH}/static-baby/images/backgrounds/baby_wallpaper.svg');
    background-size: 86%;
    background-position-y: -113%;
    background-repeat: repeat;
    height: 100%;
  }
`;
