import parser from 'ua-parser-js';

export const XS_MOBILE_MAX = 480;
export const MOBILE_MAX = 767;
export const TABLET_MIN = 768;
export const TABLET_MAX = 991;
export const DESKTOP_MIN = 992;
const DESKTOP_MAX = 1999;
export const LG_DESKTOP_MIN = 1200;

export enum DeviceEnum {
  MOBILE_XS = 'mobile-xs',
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export function getDeviceType(userAgent?: string): DeviceEnum {
  const deviceType = new parser.UAParser(userAgent).getDevice().type;
  switch (deviceType) {
    case 'mobile':
      return DeviceEnum.MOBILE;
    case 'tablet':
      return DeviceEnum.TABLET;
    default:
      return DeviceEnum.DESKTOP;
  }
}

export class Device {
  deviceType: DeviceEnum;

  constructor(userAgent?: string) {
    this.deviceType = getDeviceType(userAgent);
  }

  deviceName = (): DeviceEnum => this.deviceType;

  isXsMobile = (): boolean =>
    typeof window !== 'undefined'
      ? window.matchMedia(`(max-width: ${XS_MOBILE_MAX}px)`).matches
      : [DeviceEnum.MOBILE_XS].includes(this.deviceType);

  isMobile = (): boolean =>
    typeof window !== 'undefined'
      ? window.matchMedia(`(max-width: ${MOBILE_MAX}px)`).matches
      : [DeviceEnum.MOBILE_XS, DeviceEnum.MOBILE].includes(this.deviceType);

  isTablet = (): boolean =>
    typeof window !== 'undefined'
      ? window.matchMedia(`(min-width: ${TABLET_MIN}px) and (max-width: ${TABLET_MAX}px)`).matches
      : [DeviceEnum.TABLET].includes(this.deviceType);

  isDesktop = (): boolean =>
    typeof window !== 'undefined'
      ? window.matchMedia(`(min-width: ${DESKTOP_MIN}px)`).matches || this.isLargeDesktop()
      : [DeviceEnum.DESKTOP].includes(this.deviceType);

  isLargeDesktop = (): boolean =>
    typeof window !== 'undefined'
      ? window.matchMedia(`(min-width: ${LG_DESKTOP_MIN}px)`).matches
      : false;

  notMobile = (): boolean =>
    typeof window !== 'undefined'
      ? window.matchMedia(`(min-width: ${TABLET_MIN}px)`).matches
      : !this.isMobile();

  notTablet = (): boolean =>
    typeof window !== 'undefined'
      ? window.matchMedia(`(max-width: ${MOBILE_MAX}px), (min-width: ${DESKTOP_MIN}px)`).matches
      : !this.isTablet();

  notDesktop = (): boolean =>
    typeof window !== 'undefined'
      ? window.matchMedia(`(max-width: ${TABLET_MAX}px)`).matches
      : !this.isDesktop();

  notLargeDesktop = (): boolean =>
    typeof window !== 'undefined'
      ? window.matchMedia(`(max-width: ${DESKTOP_MAX}px)`).matches
      : !this.isLargeDesktop();

  isHighPixelDensity = (): boolean =>
    typeof window !== 'undefined'
      ? window.matchMedia('(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)').matches
      : false;
}
