import React from 'react';

import { Device } from 'libs/universal/utils/device';

type DeviceContextType = {
  device?: Device;
};

type DeviceProviderProps = {
  children: JSX.Element | JSX.Element[];
  userAgent?: string;
};

const DeviceContext: React.Context<DeviceContextType> = React.createContext<DeviceContextType>({});
const { Provider } = DeviceContext;

const DeviceProvider: React.FC<DeviceProviderProps> = ({ children, userAgent }) => {
  const device = new Device(userAgent);
  return <Provider value={{ device }}>{children}</Provider>;
};

export { DeviceContext, DeviceProvider };
